import { Chip } from '@mui/material'
import { withStyles } from '@mui/styles';
import React from 'react'

export default function ChipStatus({ label, style = {}, color = 'success', pulse = true }) {
    const StyledChip = withStyles({
        root: {
            backgroundColor: color,// here you can do anything actually ,
            paddingTop: 0,
            maxHeight: 22,
            borderRadius: 6
        },
        label: {
            textTransform: 'capitalize',
            fontSize: 12,
            fontWeight: 700,

        },
    })(Chip);

    return (
        <StyledChip label={label} variant='standard' color='primary' style={{ paddingTop: 0, ...style }} className={`${pulse && "pulse"} my-2`} />
    )
}
