import React from 'react'

export default function TinyChip({
    label,
    style = {}
}) {
    return (
        <div style={{ height: 22, fontSize: 11, display: "inline-flex", backgroundColor: "white", alignItems: "center", padding: 8, borderRadius: 6, fontWeight: 700, ...style }}>
            {label}
        </div>
    )
}
