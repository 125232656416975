import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { FaRegPlayCircle } from "react-icons/fa";

export default function WinnerItem({
    number,
    award,
    name,
    image_profile_path,
    video_url,
    created_at
}) {
    const openUrlVideo = () => {
        window.open(video_url, "_blank");
    }

    return (
        <Card sx={{ my: 1 }}>
            <CardContent sx={{ display: "flex", gap: 1 }}>
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, width: "100%" }}>
                            <Box >
                                <img src={image_profile_path ? image_profile_path : "/user.jpg"} style={{
                                    maxWidth: 80,
                                    minWidth: 80,
                                    maxHeight: 80,
                                    minHeight: 80,
                                    borderRadius: 5,
                                    objectFit: "cover"
                                }} />
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", }}>
                                <Typography variant='body1' sx={{ fontSize: "1em", fontWeight: 600 }}>{name}</Typography>
                                <Typography variant='body1' sx={{ fontSize: "0.7em" }}>Prêmio: <b>{award}</b></Typography>
                                <Typography variant='body1' sx={{ fontSize: "0.7em" }}>Número da sorte: <b>{number}</b></Typography>
                                <Typography variant='body1' sx={{ fontSize: "0.7em" }}>Data da compra: <b>{moment(created_at).format("DD/MM/YYYY HH:mm")}</b></Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        mt: 1,
                        mb: 1,
                    }}>
                        {
                            video_url &&
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                                <Button fullWidth onClick={openUrlVideo} variant='contained' color='success'><FaRegPlayCircle style={{ marginRight: 10 }} /> Assistir entrega  </Button>
                            </Box>
                        }
                    </Grid>
                </Grid>



            </CardContent>

        </Card>
    );
}
