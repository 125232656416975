import React from 'react'
import { Box, Card, CardContent, CardMedia, Chip, Typography } from '@mui/material'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ChipStatus from './ChipStatus/ChipStatus';
import TinyChip from '../chips/tiny-chip';

export default function CardRaffle({
    title,
    description,
    chipTextPromotion,
    slug,
    drawDate,
    image,
    onClick,
    raffle
}) {
    return (
        <Box onClick={() => onClick(slug)} className="cursor-pointer">
            <Card sx={{ position: "relative" }}>
                <LazyLoadImage
                    alt={"Sorteio"}
                    itemProp='image'
                    delayMethod='debounce'
                    fetchPriority='high'
                    style={{
                        maxHeight: 408,
                        minHeight: 408,
                        objectFit: "cover",
                    }}
                    visibleByDefault
                    effect='blur'
                    width={"100%"}
                    src={image}
                    wrapperProps={{
                        // style: { transitionDelay: ".01s" },
                        alt: "Sorteio",
                    }}
                />
                <div style={{
                    position: "absolute",
                    display: "flex",
                    paddingTop: 0,
                    flexDirection: "column",
                    zIndex: 10,
                    left: 0,
                    right: 0,
                    bottom: 0

                }}>
                    <div style={{ backdropFilter: "blur(2px)", width: "100%", height: "100%", background: "linear-gradient(rgba(0, 0, 0, 0.3) 0px, rgb(0, 0, 0))", top: 0, zIndex: 1, content: "", position: "absolute" }} />
                    <div style={{ zIndex: 100, color: "white", padding: 8 }}>
                        <Typography sx={{ margin: 0, padding: 0 }} variant='subtitle1' fontWeight={500}>{title}</Typography>
                        <Typography sx={{ fontSize: 11.5, fontWeight: 400 }}>{description}</Typography>
                        <div style={{ marginBottom: 10 }}>
                            {
                                (chipTextPromotion && raffle.status == "active") && <ChipStatus label={chipTextPromotion} />
                            }
                        </div>
                        {
                            raffle.status !== "active" &&
                            <TinyChip label="Concluído" style={{ color: "white", backgroundColor: "gray" }} />
                        }
                    </div>
                </div>
            </Card>
        </Box>
    )
}
